<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                {{$t('Información del Pago')}}
            </template>
            <template v-slot:subtitle>
                {{$t('solicitada')}}
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
                        <h4>{{$t('Factura a Pagar')}}</h4>
						<DataTable :value="formData.factura" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
                            <Column field="fecha" :header="$t('Fecha')" :sortable="true" headerStyle="width: 100px">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
                            <Column field="poliza" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">	
								<template #body="{data}">
									{{data.codigo}}
								</template>
							</Column>
                            <Column field="nombre" :header="$t('Tipo')" :sortable="true">	
								<template #body="{data}">
									<div v-if="i18n.locale() == 'es'">{{data.nombre}}</div>
									<div v-else>{{data.name}}</div>
								</template>
							</Column>
                            <Column field="monto" :header="$t('Monto')" :sortable="true">	
								<template #body="{data}">
									<div v-if="data.monto<0" style="color:red;">
										{{moneda(data.monto)}}
									</div>
									<div v-else>
										{{moneda(data.monto)}}
									</div>
								</template>
							</Column>
                            <Column field="vencimiento" :header="$t('Vencimiento')" :sortable="true">	
								<template #body="{data}">
									{{data.vencimiento}}
								</template>
							</Column>
						</DataTable>
					</div>
                    <div class="p-field p-col-12 p-md-12" v-if="formData.factura_monto>0">
						<Button :label="$t('Agregar Pago')" icon="pi pi-money-bill" class="p-button-info p-mr-2 p-mb-2" @click="CargarPago()" style="width: 200px;"/>
                        <small v-show="validationErrors.pago && submitted" style="color: red;">{{$t('Debe reportar el pago')}}</small>
						<DataTable :value="pagos" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column :header="$t('Fecha')">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
							<Column :header="$t('Tipo')">	
								<template #body="{data}">
									<div v-if="i18n.locale() == 'es'">{{data.tipo.nombre}}</div>
									<div v-if="i18n.locale() == 'en'">{{data.tipo.name}}</div>									
								</template>
							</Column>
							<Column :header="$t('Referencia')">	
								<template #body="{data}">
									{{data.referencia}} {{data.cash}}
                                    <img style="width: 100px;" :src="url.upload+data.img" v-if="data.img"/>
								</template>
							</Column>
							<Column :header="$t('Monto')">	
								<template #body="{data}">
                                    {{moneda(data.monto)}}
								</template>
							</Column>
                            <Column :header="$t('Eliminar')">
								<template #body="{data}">
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="EliminarPago(data)"/>
                                </template>
                            </Column>
                            
						</DataTable>                   
					</div>

					<div class="p-col-6"></div>
					<div class="p-col-3"><h4><strong>{{$t('Monto a Pagar')}}:</strong></h4></div>
					<div class="p-col-3" v-if="formData.factura_monto>0">
						<h4><strong>{{moneda(formData.factura_monto)}}</strong></h4>
					</div>
					<div class="p-col-3" v-else>
						<h4><strong>{{moneda(0)}}</strong></h4>
					</div>
					<div class="p-col-6" v-if="pago_monto>0"></div>
					<div class="p-col-3" v-if="pago_monto>0"><h4><strong>{{$t('Total de Pagos')}}:</strong></h4></div>
					<div class="p-col-3" v-if="pago_monto>0">
						<h4><strong>{{moneda(pago_monto)}}</strong></h4>
					</div>
					<div class="p-col-6"></div>
					<div class="p-col-3"><h4><strong>{{$t('Balance')}}:</strong></h4></div>
					<div class="p-col-3" v-if="formData.factura_monto>0">
						<h4><strong>{{moneda(pago_monto-formData.factura_monto)}}</strong></h4>
					</div>
					<div class="p-col-3" v-else style="color:red">
						<h4><strong>{{moneda(formData.factura_monto)}}</strong></h4>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :label="$t('Enviar Pago')" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success"/>
                </div>
            </template>
        </Card>

		<Dialog v-model:visible="Dialog" :style="{width: size}" :header="$t('Agregar Pago')" :modal="true" class="p-fluid" :maximizable="true">
            <br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-8">
                        <div v-for="(stats, index) in tipos" :key="index">
                            <RadioButton id="tipo" name="subcateg" :value="stats.code" v-model="pago.tipo"/>
                            <strong v-if="i18n.locale() == 'es'"> {{stats.nombre}}<br><br></strong>
                            <strong v-else> {{stats.name}}<br><br></strong>
                        </div>
					</div>
					<div class="p-col-4" v-if="pago.tipo">
                        <label>{{$t('Fecha')}}</label>
						<InputMask v-model="pago.fecha" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
					</div>
					<div class="p-col-12" v-if="pago.tipo">
						<div v-if="pago.tipo==2">
							<label>{{$t('Banco de Origen')}}</label>
							<Dropdown v-model="pago.banco_orig" :options="bancos" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" />
						</div>
					</div>
					<div class="p-col-8" v-if="pago.tipo==4">
                        <label>{{$t('Nombre tarjetahabiente')}}</label>
						<InputText v-model="pago.tarj_nom" type="text"/>
					</div>
					<div class="p-col-4" v-if="pago.tipo==4">
                        <label>{{$t('4 últimos dígitos')}}</label>
						<InputMask v-model="pago.tarj_num" mask="9999"/>
					</div>

					<div class="p-col-7" v-if="pago.tipo>0 && pago.tipo<5">
                        <label>{{$t('Referencia')}}</label>
						<InputNumber v-model="pago.referencia" required="true" min="0" mode="decimal" :useGrouping="false"/>
					</div>
					<div class="p-col-7" v-if="pago.tipo==5">
                        <label>{{$t('Observación')}}</label>
						<InputText v-model="pago.cash" type="text"/>
					</div>
					<div class="p-col-5" v-if="pago.tipo">
                        <label>{{$t('Monto')}}</label>
                        <InputNumber v-model="pago.monto" required="true" min="1" :minFractionDigits="2"/>
					</div>
                    <div class="p-field p-col-12 p-md-4" v-if="pago.tipo<4 && pago.tipo>0">
                        <FileUpload :chooseLabel="$t('Subir Foto')+ ' JPG,JPEG'" uploadLabel="Cargar" mode="basic" :name="token" :url="url.api+'upload.php'" accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader(token)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small>
                    </div>
					<div class="p-field p-col-4 p-md-4" v-if="pago.tipo<4 && pago.tipo>0">
						<img style="width: 100px;" :src="url.upload+pago.img" v-if="pago.img"/>
					</div>
				</div>
			</div>
			<template #footer>
				<Button :label="$t('Agregar')" icon="pi pi-check" class="p-button-secondary" @click="Agregar()"/>
				<Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
			</template>
		</Dialog>

    </div>
</template>

<script>
import API from "@/service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import Token from "uuid-token-generator";

export default {
    props: {
        formData: Object
    },
    data () {
        return {
			size: '40vw',
			i18n: null,
            submitted: false,
            validationErrors: {},
            bancos: [],
            pagos: [],
            tipos: [],
            pago_monto: 0,
            Dialog: false,
            pago: {
                fecha: null,
                tipo: null,
                banco_orig: { "nombre": "Banco del Caribe (BDC)", "name": "Bank of the Caribbean (BDC)", "code": 0 },
                referencia: null,
                tasa: null,
                moneda: 0,
                monto: null,
                img: null,
				cash: null,
				tarj_num: null,
				tarj_nom: null
            },
			token: null,
        }
    },
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
        const Consulta = new API('Pagos');
		Consulta.Ini('SPagos').then(result => {
			//this.$store.state.error = result.consult;	
			this.tipos = result.consult.tipos;
			this.bancos = result.consult.bancos;
		}); 
	},
    methods: {
        CargarPago() {
            const tokgen = new Token();
            this.token = tokgen.generate();
            this.pago = {
                fecha: null,
                tipo: null,
                banco_orig: { "nombre": "Banco del Caribe (BDC)", "name": "Bank of the Caribbean (BDC)", "code": 0 },
                referencia: null,
                tasa: null,
                monto: null,
                moneda: 0,
                img: null,
				cash: null,
				tarj_num: null,
				tarj_nom: null
            };
            this.Dialog = true;
        },
		EliminarPago(selec) {
            this.pago_monto = 0;
			this.pagos = this.pagos.filter(val => val !== selec);
            this.pagos.forEach(element => {
                this.pago_monto = parseFloat(this.pago_monto) + parseFloat(element.monto);
            }); 
		},
		myUploader(img) {
			this.$store.commit('Loading');
			const Consulta = new API('Pagos');
			Consulta.Procesar('SPagos',{
				caso: 	'RevFoto',
				foto: img,
			}).then(response => {
				if(response.result){
                    //this.pago.img = this.token;
                    this.pago.img = response.result;
				} else {
					this.foto = response.result;
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
                const tokgen = new Token(256);
                this.img = tokgen.generate();
				this.$store.commit('Loading');
			});
		},
        Agregar(){
            if (this.pago.tipo == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el tipo de pago', life: 5000});
			} else if (this.pago.fecha.length!=10) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Indique la fecha del pago', life: 10000});
			} else if (this.pago.banco_orig == null && this.pago.tipo == 2) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el banco', life: 5000});
			} else if (this.pago.referencia == null && this.pago.tipo<4) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el número de referencia del pago', life: 5000});
			} else if (this.pago.monto == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el monto del pago', life: 5000});
			} else if (this.pago.img == null && this.pago.tipo<4) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar la imagen de la transferencia', life: 5000});
			} else {
				this.$store.commit('Loading');
				this.Dialog = false;
				this.tipos.forEach(element => {
					if(element.code == this.pago.tipo){
						this.pago.tipo = element;
					}
				});
				this.pagos.push(this.pago);
				this.pago_monto = parseFloat(this.pago_monto) + parseFloat(this.pago.monto);
				this.$store.commit('Loading');

            }
        },
        fdate(dato){
            const [day, month, year] = dato.split('/');
            return new Date(+year, +month - 1, +day);
        },
        complete() {
			if (this.validateForm() ) {
				this.$store.commit('Loading');
				var pagos = this.pagos;
				pagos.forEach(element => {
					element.fecha = this.fdate(element.fecha);
				});
				const Consulta = new API('Pagos');
				Consulta.Procesar('SPagos',{		
					caso: 	'INS',
					poliza: this.formData.poliza,
					usuario: this.formData.usuario,
					factura: this.formData.factura,
					factura_monto: this.formData.factura_monto,
					pagos: pagos,
					pago_monto: this.pago_monto,
					leng: this.i18n.locale(),
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success',
							summary: 'Éxito',
							detail: 'Información Procesada', 
							life: 3000
						});
						this.$store.commit('Loading');
						this.$router.push({ path: '/paymentreview'});
					} else {
						this.$toast.add({
							severity:'warn',
							summary: 'Advertencia',
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
					}
				});
			}
        },
		prevPage() {
            this.$emit('prev-page', {pageIndex: 1});
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('next-page', {formData: {pagos: this.pagos, pago_monto: this.pago_monto}, pageIndex: 1});
            }
        },
        validateForm() {
            if (this.formData.factura_monto>0 && (this.pagos.length==0 || ((parseFloat(this.pago_monto)-parseFloat(this.formData.factura_monto)).toFixed(2)<0)))
                this.validationErrors['pago'] = true;
            else
                delete this.validationErrors['pago'];
            return !Object.keys(this.validationErrors).length;
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},

    }
}
</script>